import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Transition } from 'Shared/Transition';
import { setLoading } from 'Actions/loading';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import http from 'Utils/http';
import { Controller, useForm } from 'react-hook-form';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';

const FlowExportFlow = ({ visible, setVisible, update }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [code, setCode] = useState('');

    const {
        control,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({
        defaultValues: {
            code: ''
        }
    });

    const onClose = () => {
        setCode('');
        setVisible(false);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type !== 'application/json') {
                enqueueSnackbar('Please upload the file in JSON format.', {
                    action: CustomSnackbar,
                    variant: 'error',
                });
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const parsed = JSON.parse(e.target.result);
                    setCode(JSON.stringify(parsed, null, 4));
                    setValue('code', JSON.stringify(parsed, null, 4));
                } catch (err) {
                    enqueueSnackbar('Error parsing JSON file.', {
                        action: CustomSnackbar,
                        variant: 'error',
                    });
                }
            };
            reader.readAsText(file);
        }
    };

    const isValidJSON = (jsonString) => {
        try {
            JSON.parse(jsonString);
            return true;
        } catch (error) {
            return false;
        }
    };

    const handleUploadSubmit = () => {
        const codeValue = getValues('code');
        if (!isValidJSON(codeValue)) {
            enqueueSnackbar('Invalid JSON format', {
                action: CustomSnackbar,
                variant: 'error',
            });
            return;
        }

        setLoading(true);
        http.post('/flow/import', codeValue)
            .then(({ data }) => {
                if (data.status !== 'success') {
                    enqueueSnackbar(`Error sending data: ${data.message}.`, {
                        action: CustomSnackbar,
                        variant: 'error',
                    });
                } else {
                    enqueueSnackbar(`Flow - ${data.data.name} has been added.`, {
                        action: CustomSnackbar,
                        variant: 'success',
                    });
                }
                onClose();
                update(true)
            })
            .catch((err) => {
                enqueueSnackbar(`Error sending data: ${err.message}`, {
                    action: CustomSnackbar,
                    variant: 'error',
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Dialog
            open={visible}
            onClose={onClose}
            TransitionComponent={Transition}
            sx={{
                '& .MuiDialog-container': {
                    alignItems: 'flex-start',
                    paddingTop: '150px',
                },
                '& .MuiDialog-paper': {
                    minWidth: '450px',
                    maxWidth: '900px',
                },
            }}
        >
            <Box component="form">
                <DialogTitle
                    variant="h1"
                    sx={{
                        paddingBottom: '6px',
                        textAlign: 'center',
                        color: '#3B465C',
                    }}
                >
                    Import Flow
                </DialogTitle>

                <DialogContent sx={{ maxHeight: '560px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {/* File Upload */}
                        <Box sx={{ marginBottom: '20px' }}>
                            <input
                                type="file"
                                accept=".json,application/json"
                                onChange={handleFileChange}
                            />
                            <FormHelperText>
                                Use only json file format
                            </FormHelperText>
                        </Box>

                        {/* JSON Editor */}
                        <FormControl sx={{ width: '100%', marginBottom: '12px' }}>
                            <Controller
                                name="code"
                                control={control}
                                rules={{
                                    required: 'JSON data is required',
                                    validate: (value) => isValidJSON(value) || 'Invalid JSON format',
                                }}
                                render={({ field }) => (
                                    <>
                                        <Editor
                                            {...field}
                                            value={code}
                                            onValueChange={(newCode) => setCode(newCode)}
                                            highlight={(newCode) => highlight(newCode, languages.js)}
                                            padding={10}
                                            tabSize={4}
                                            style={{
                                                borderRadius: '4px',
                                                borderColor: errors.code ? '#f44336': 'rgba(0, 0, 0, 0.23)',
                                                borderWidth: '1px',
                                                borderStyle: 'solid',
                                                backgroundColor: '#F2F5F8',
                                                minHeight: '150px'
                                            }}
                                        />
                                        {errors.code && (
                                            <FormHelperText error>
                                                {errors.code.message}
                                            </FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                        </FormControl>
                    </Box>
                </DialogContent>

                {/* Actions */}
                <DialogActions sx={{ padding: '16px 24px' }}>
                    <Button onClick={onClose} variant="outlined">
                        Close
                    </Button>
                    <Button onClick={handleUploadSubmit} variant="contained">
                        Import
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default FlowExportFlow;
