import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'Actions/loading';
import { setFlowMethodConditionList } from 'Actions/flowMethodList';
import {
  Box,
  Grid,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Stack,
  TextField,
  IconButton,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { CustomBlackSwitch } from 'Shared/CustomBlackSwitch';
import { CloseIcon } from 'Shared/Icons';
import { useSnackbar } from 'notistack';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import { AlertDialog } from 'Shared/AlertDialog';
import http from 'Utils/http';
import { useCreateUrl } from 'Hooks/useCreateUrl';

export const FlowRuleForm = ({
                                 index,
                                 transformation_id,
                                 condition_id,
                                 preCondition,
                                 setPreCondition,
                                 switchType = null
                             }) => {
    const dispatch = useDispatch();
    const { createUrl } = useCreateUrl();
    const { enqueueSnackbar } = useSnackbar();

    const modalText = 'Are you sure you want to delete condition?';

    const fieldList = useSelector((state) => state.flowMethodList.fieldList?.filter((item) => item.name !== 'hidden_field_in_input_port'));
    const conditions = useSelector((state) => state.flowMethodList?.conditionList?.filter((item) => item.transformation_id === transformation_id) ?? []);
    const flowMethods = useSelector((state) => state.flowMethodList.flowMethodList);
    const operatorsList = useSelector((state) => state.operators.operators);
    const operatorsListSingle = useSelector((state) => state.operators.operators.logical.filter((i) => i.single));

    // Memoize selectors
    const memoizedFieldList = useMemo(() => fieldList, [fieldList]);
    const memoizedConditions = useMemo(() => conditions, [conditions]);
    const memoizedFlowMethods = useMemo(() => flowMethods, [flowMethods]);
    const memoizedOperatorsList = useMemo(() => operatorsList, [operatorsList]);
    const memoizedOperatorsListSingle = useMemo(() => operatorsListSingle, [operatorsListSingle]);

    const getDefaultCondition = () => {
        if (!preCondition) {
            const dataSelectOperandB =
                memoizedConditions[index]?.constant ? 'CONSTANT' :
                    memoizedConditions[index]?.condition_id ? 'PREVCONDITION' :
                        'FIELD';

            return {
                select_operand_a: 'FIELD',
                operand_a: memoizedConditions[index]?.operand_a || '',
                select_operand_b: dataSelectOperandB,
                operand_b: memoizedConditions[index]?.operand_b || '',
                operation_id: memoizedConditions[index]?.operation_id || 'null',
                constant: memoizedConditions[index]?.constant || ''
            };
        }

        return {
            select_operand_a: 'FIELD',
            select_operand_b: 'FIELD',
            operand_a: '',
            operation_id: 'null',
            operand_b: '',
            constant: ''
        };
    };
    const defaultCondition = getDefaultCondition();
    const findOperation = () => !!defaultCondition?.operation_id && memoizedOperatorsListSingle.some((item) => item.id === defaultCondition.operation_id);

    const getInitialActiveState = (condition)  => {
        if (!condition.operand_a) return null;

        if (condition.select_operand_b === "FIELD") {
            return condition.operand_b;
        }

        if (condition.select_operand_b === "CONSTANT") {
            return condition.constant;
        }

        return null;
    }

    const [switchValue, setSwitchValue] = useState(switchType !== 'and');
    const [needDisable, setNeedDisable] = useState(findOperation());
    const [isActiveModal, setIsActiveModal] = useState(false);
    const [isDropdown, setIsDropdown] = useState(false);
    const [visibleFieldsList, setVisibleFieldsList] = useState(defaultCondition?.select_operand_b);
    const [isActive] = useState(() => getInitialActiveState(defaultCondition));
    const onClose = () => setIsActiveModal(false);
    const onAgree = () => {
        setIsActiveModal(false);
        deleteRuleHandler();
    };
    const deleteRuleHandler = () => {
        if (preCondition) {
            enqueueSnackbar('You yet not save condition!', {
                action: CustomSnackbar,
                variant: 'warning'
            });
            setPreCondition([]);
            return;
        }

        if (!memoizedConditions.length) {
            enqueueSnackbar('You yet not save condition!', {
                action: CustomSnackbar,
                variant: 'warning'
            });
            return;
        }

        dispatch(setLoading(true));
        http.delete(createUrl(`/condition/${condition_id}/delete`)).then((res) => {
            dispatch(setFlowMethodConditionList(res.data.flowMethod.conditions));
            enqueueSnackbar('Condition has been deleted', {
                action: CustomSnackbar,
                variant: 'success'
            });
        }).catch((err) => {
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        }).finally(() => {
            dispatch(setLoading(false));
        });
    };

    const {
        register,
        watch,
        control,
        setValue,
        getValues,
    } = useForm({
        defaultValues: {
            select_operand_a: defaultCondition.select_operand_a,
            operand_a: defaultCondition.operand_a,
            operation_id: defaultCondition.operation_id,
            select_operand_b: defaultCondition.select_operand_b,
            operand_b: defaultCondition.operand_b,
            constant: defaultCondition.constant
        },
        mode: 'onChange',
    });

    const handleError = useCallback((err) => {
        dispatch(setLoading(false));
        enqueueSnackbar(`${err.response.data.message}`, {
            action: CustomSnackbar,
            variant: 'error',
        });
    }, [dispatch, enqueueSnackbar]);

    const handleSwitchValue = useCallback(() => {
        setSwitchValue(!switchValue);
    }, [setSwitchValue, switchValue]);

    const saveRule = (needDelete = false) => {
        if (needDelete) {
            deleteRule();
            return;
        }

        const type = getType();
        const operandB = getOperandB();

        const paramsForAddCondition = {
            type,
            transformation_id: transformation_id,
            operand_a: getValues('operand_a'),
            operation_id: getValues('operation_id'),
            ...operandB,
        };

        if (visibleFieldsList === 'PREVCONDITION') {
            paramsForAddCondition.condition_id = memoizedConditions[index - 1]?.id;
        }

        if (!memoizedConditions.length || preCondition) {
            dispatchWithLoading(() =>
                http.post(createUrl('/condition/add'), paramsForAddCondition).then((res) => {
                    dispatch(setFlowMethodConditionList(res.data.flowMethod.conditions));
                    setPreCondition([]);
                    enqueueSnackbar('Rule has been saved', {
                        action: CustomSnackbar,
                        variant: 'success',
                    });
                }).catch(handleError)
            );
        } else {
            updateRule(paramsForAddCondition);
        }
    };

    const deleteRule = () => {
        const updId = memoizedConditions[index]?.id;
        if (!updId || getValues('operation_id') === 'null') {
            return;
        }

        dispatchWithLoading(() =>
            http.delete(createUrl(`/condition/${updId}/delete`)).then((res) => {
                dispatch(setFlowMethodConditionList(res.data.flowMethod.conditions));
                setPreCondition([]);
            }).catch(handleError)
        );
    };

    const updateRule = (params) => {
        const updId = memoizedConditions[index]?.id;
        if (!updId) {
            return;
        }

        dispatchWithLoading(() =>
            http.post(createUrl(`/condition/${updId}/update`), params).then(() => {
                setPreCondition([]);
                enqueueSnackbar('Rule has been saved', {
                    action: CustomSnackbar,
                    variant: 'success',
                });
            }).catch(handleError)
        );
    };

    const getType = () => {
        if (index === 0) {
            return 'single';
        }
        return !switchValue ? 'and' : 'or';
    };

    const getOperandB = () => {
        if (!needDisable) {
            if (visibleFieldsList === 'CONSTANT') {
                return { constant: getValues('constant'), operand_b: null };
            }
            if (visibleFieldsList === 'FIELD') {
                return { operand_b: getValues('operand_b'), constant: null };
            }
        }
        return {};
    };

    const dispatchWithLoading = (action) => {
        dispatch(setLoading(true));
        action().finally(() => dispatch(setLoading(false)));
    };

    useEffect(() => {
        const subscription = watch((values, { name }) => {
            if (values[name]) {
                if (name === 'operation_id') {
                    setNeedDisable(memoizedOperatorsListSingle.some(item => item.id === values.operation_id));
                }
                const operandB = values.select_operand_b;

                switch (operandB) {
                    case 'CONSTANT':
                    case 'FIELD':
                    case 'PREVCONDITION':
                        setVisibleFieldsList(operandB);
                        break;

                    default:
                }
            }
        });

        return () => subscription.unsubscribe();
    }, [watch, memoizedOperatorsListSingle, createUrl, memoizedFlowMethods, setValue, setNeedDisable]);

    return (
        <>
            <AlertDialog visible={isActiveModal} onClose={onClose} onAgree={onAgree} text={modalText}/>
            <Box
                component="form"
                sx={{
                    marginBottom: '10px',
                    padding: '13px 12px 12px 12px',
                    background: 'rgba(65, 77, 101, 0.05)',
                    borderRadius: '6px',
                }}
            >
                <Box sx={{display: 'flex', alignItems: 'center', padding: '0', cursor: 'pointer'}}
                     onClick={() => {
                         setIsDropdown(!isDropdown)
                     }}>
                    <Typography sx={{
                        marginRight: '16px',
                        fontWeight: '700',
                        letterSpacing: '0.1em',
                        textTransform: 'uppercase',
                        color: 'rgba(65, 77, 101, 0.85)',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <span>#{index + 1} Rule</span>
                        {isActive ? <Box sx={{
                            display: 'flex',
                            marginLeft: '10px',
                            width: '10px',
                            height: '10px',
                            background: '#1BBC6F',
                            borderRadius: '5px'
                        }}></Box> : null}
                    </Typography>

                    {index !== 0 && (
                        <Stack direction="row" alignItems="center">
                            <Typography color="#949DB0">and</Typography>
                            <CustomBlackSwitch checked={switchValue} onChange={handleSwitchValue}/>
                            <Typography color="#949DB0">or</Typography>
                        </Stack>
                    )}

                    <Box sx={{ marginLeft: 'auto' }}>
                        {(index !== 0) && (
                            <IconButton
                                type="button"
                                onClick={() => {
                                    setIsActiveModal(true);
                                }}>
                                <CloseIcon/>
                            </IconButton>
                        )}
                        <IconButton>
                            <SettingsIcon
                                type="button"
                                sx={{ color: '#949DB0' }}/>
                        </IconButton>
                    </Box>
                </Box>

                {isDropdown && (<Grid container columnSpacing={'10px'}>
                    <Grid item xs={4}>
                        <Typography
                            sx={{
                                lineHeight: '17px',
                                marginBottom: '10px',
                                fontWeight: '500',
                                color: '#949DB0',
                            }}
                        >
                            Select Operands
                        </Typography>
                        <FormControl sx={{ width: '100%', marginBottom: '12px' }}>
                            <InputLabel>1st Operand</InputLabel>
                            <Controller
                                id="select_operand_a"
                                name="select_operand_a"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        label="1st Operand"
                                    >
                                        <MenuItem value="FIELD">Field</MenuItem>
                                    </Select>
                                )}
                            />
                        </FormControl>

                        <FormControl sx={{ width: '100%', marginBottom: '12px' }}>
                            <InputLabel>1st Operand Value</InputLabel>
                            <Controller
                                id="operand_a"
                                name="operand_a"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Select {...field} label="1st Operand Value">
                                        {memoizedFieldList?.map(({ id, name }) => (
                                            <MenuItem key={`${id}_1st_field_id`} value={id}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl
                            sx={{
                                width: '100%',
                                marginTop: '27px',
                                marginBottom: '12px',
                            }}
                        >
                            <InputLabel>Condition</InputLabel>
                            <Controller
                                id="operation_id"
                                name="operation_id"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        label="Condition"
                                    >
                                        <MenuItem value={'null'}>No condition</MenuItem>
                                        {memoizedOperatorsList?.logical.map(({ id, operation }) => (
                                            <MenuItem key={`${id}_logical_no_condition`} value={id}>
                                                {operation}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography
                            sx={{
                                lineHeight: '17px',
                                marginBottom: '10px',
                                fontWeight: '500',
                                color: '#949DB0',
                            }}
                        >
                            Select Operands
                        </Typography>

                        <FormControl
                            sx={{
                                width: '100%',
                                marginBottom: '12px',
                            }}
                        >
                            <InputLabel>2nd Operand</InputLabel>
                            <Controller
                                id="select_operand_b"
                                name="select_operand_b"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        label="2nd Operand"
                                        disabled={needDisable}
                                    >
                                        <MenuItem value={'CONSTANT'}>Constant</MenuItem>
                                        <MenuItem value={'FIELD'}>Field</MenuItem>
                                    </Select>
                                )}
                            />
                        </FormControl>

                        {visibleFieldsList === 'CONSTANT' && (
                            <FormControl
                                sx={{
                                    width: '100%',
                                    marginBottom: '12px',
                                }}
                            >
                                <TextField
                                    label="Constant"
                                    disabled={needDisable}
                                    {...register('constant', {
                                        required: true,
                                    })}
                                />
                            </FormControl>
                        )}

                        {visibleFieldsList === 'FIELD' && (
                            <FormControl
                                sx={{
                                    width: '100%',
                                    marginBottom: '12px',
                                }}
                            >
                                <InputLabel>2nd Operand Value</InputLabel>
                                <Controller
                                    id="operand_b"
                                    name="operand_b"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            label="2nd Operand Value"
                                            disabled={needDisable}
                                        >
                                            {memoizedFieldList?.map(({ id, name }) => (
                                                <MenuItem key={`${id}_2nd_field_id`} value={id}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        )}

                        {visibleFieldsList === 'PREVCONDITION' && (
                            <FormControl
                                sx={{
                                    width: '100%',
                                    marginBottom: '12px',
                                }}
                            >
                                <TextField
                                    disabled
                                    label={`Prev Id: ${memoizedConditions[index - 1]?.id}`}
                                    {...register('condition_id', {
                                        required: true,
                                    })}
                                />
                            </FormControl>
                        )}

                        <Button
                            variant="contained"
                            sx={{
                                width: '100%',
                            }}
                            onClick={() => {saveRule(false)}}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>)}
            </Box>
        </>
    );
};
