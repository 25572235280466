import React, { useState, useEffect, useCallback, useMemo } from 'react';
import http from 'Utils/http';
import {
    Box,
    Grid,
    Button,
    Typography,
    Dialog,
    DialogContent,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    IconButton,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'Actions/loading';
import { setContentMatchModal, setVisibleMatchModal } from 'Actions/matchModal';
import { setFlowMethodResponse } from 'Actions/flowMethodResponse';
import {
    setFlowMethodList,
    setFlowMethodTransformationList,
    setFlowMethodConditionList,
    setFlowMethodFieldList,
} from 'Actions/flowMethodList';
import { FlowTransformationList } from 'Shared/flows/flows-interface/FlowTransformationList';
import { Transition } from 'Shared/Transition';
import { TransformationIcon } from 'Shared/Icons';
import { TransformationButtonIcon } from 'Shared/Icons';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import { CloseIcon } from 'Shared/Icons';
import { useCreateUrl } from 'Hooks/useCreateUrl';

function BeautifyJson({ jsonStr }) {
    const beautifiedJson = JSON.stringify(JSON.parse(jsonStr), null, 4);

    return (
        <pre style={{ overflow: 'auto', maxHeight: '300px' }}>{beautifiedJson}</pre>
    );
}

const FlowMatchModal = ({id}) => {
    const dispatch = useDispatch();
    const { createUrl } = useCreateUrl();
    const { enqueueSnackbar } = useSnackbar();

    const { transformationList } = useSelector(state => state.flowMethodList);
    const flowMethods = useSelector((state) => state.flowMethodList.flowMethodList);
    const apiListRedux = useSelector((state) => state.APIs.APIs);
    const operatorsList = useSelector((state) => state.operators.operators);

    const visible = useSelector((state) => state.matchModal.visible);
    const title = useSelector((state) => state.matchModal.content.title);
    const method = useSelector((state) => state.matchModal.content.method);
    const name = useSelector((state) => state.matchModal.content.name);
    const field = useSelector((state) => state.matchModal.content.field);
    const contentId = useSelector((state) => state.matchModal.content.id);

    // Memoize selectors
    const memoizedTransformations = useMemo(() => transformationList, [transformationList]);

    const [responseTemplate, setResponseTemplate] = useState('');
    const [methodDefault, setMethodDefault] = useState('');
    const [fieldDefault, setFieldDefault] = useState('');
    const [defaultTitle, setDefaultTitle] = useState('');
    const [defaultApiValue, setDefaultApiValue] = useState(null);
    const [defaultMethodValue, setDefaultMethodValue] = useState(null);
    const [defaultFieldValue, setDefaultFieldValue] = useState(null);
    const [apiList, setApiList] = useState([]);
    const [methodsList, setMethodsList] = useState([]);
    const [fieldsList, setFieldsList] = useState([]);
    const [visibleFormTransformation, setVisibleFormTransformation] = useState(false);
    const [disableAddTransformation, setDisableAddTransformation] = useState(true);
    const [presetOperation, setPresetOperation] = useState(0);
    const [isHiddenField, setIsHiddenField] = useState(false);
    const [hasHiddenField, setHasHiddenField] = useState(false);
    const [innerSetParams, setInnerSetParams] = useState({
        flow_method_id: '',
        source_id: ''
    });

    const {
        control,
        getValues,
        setValue,
        watch
    } = useForm({
        defaultValues: {
            api: '',
            method: '',
            field: '',
        },
        mode: 'onChange'
    });

    // Update FlowMethodList
    const updateFlowMethods = useCallback(async () => {
        await http.get(createUrl(`/flow/${id}/method/list`)).then((res) => {
            dispatch(setFlowMethodList(res.data.flowMethods));
        })
    }, [id, createUrl, dispatch]);

    // Call only once with component initialization
    const getDefaultRequest = useCallback(async () => {
        try {
            dispatch(setLoading(true));
            const fieldRes = await http.get(createUrl(`/user/flow/method/${contentId}/field/${field.id}`));
            const { transformations } = fieldRes.data.field;

            if (!transformations && !transformations?.length) {
                setDisableAddTransformation(false);
                setDefaultApiValue(null);
                setDefaultMethodValue(null);
                setDefaultFieldValue(null);
                setIsHiddenField(false);
                setValue('api', '');
                setValue('method', '');
                setValue('field', '');
                return false;
            } else {
                setDisableAddTransformation(true);
            }

            const conditions = transformations.flatMap((transformation) => transformation.conditions);
            const flowMethodId = transformations[0].flow_method_id;

            dispatch(setFlowMethodConditionList(conditions));
            dispatch(setFlowMethodTransformationList(transformations));
            const res2 = await http.get(createUrl(`/flow/method/${flowMethodId}/get`));
            const { response, integration_id, name } = res2.data.flowMethod.method;
            const integrationId = transformations[0].source_id;
            const filteredApi = apiList.find((item) => item.id === integration_id);
            const filteredField = response.find((item) => item.id === integrationId);

            setDefaultApiValue({
                value: filteredApi.id,
                label: filteredApi.name,
            });

            setDefaultMethodValue({
                value: flowMethodId,
                label: name,
            });

            setDefaultFieldValue({
                value: filteredField.id,
                label: filteredField.name,
            });

            if (filteredField.name !== 'hidden_field_in_input_port') {
                setDefaultTitle(`${filteredApi.name}.${name}.${filteredField?.name}`);
            } else {
                setDefaultTitle('');
            }

            dispatch(setFlowMethodFieldList(response));
            setVisibleFormTransformation(true);
            setValue('api', filteredApi.id);
            setMethodDefault(flowMethodId);
            setFieldDefault(filteredField.id);
            if (filteredField.name === 'hidden_field_in_input_port') {
                setIsHiddenField(true);
            } else {
                setIsHiddenField(false);
            }
        } catch (err) {
            console.error(err, 'err');
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, apiList, field, contentId, createUrl, setDisableAddTransformation, setValue, setMethodDefault, setFieldDefault, setDefaultTitle]);

    useEffect(() => {
        if (defaultMethodValue?.value && flowMethods.length) {
            const flowMethod = flowMethods.find(item => item.id === defaultMethodValue.value);
            if (flowMethod?.method?.response_template) {
                setResponseTemplate(JSON.stringify(flowMethod.method.response_template));
            }
        }
    }, [defaultMethodValue, setResponseTemplate, flowMethods]);

    useEffect(() => {
        visible && getDefaultRequest();
    }, [visible, getDefaultRequest]);

    useEffect(() => {
        if (flowMethods.length !== 0) {
            const uniqueIntegrationIds = [...new Set(flowMethods.map(item => item.method.integration_id))];
            const list = apiListRedux.filter(api => uniqueIntegrationIds.includes(api.id));
            list.forEach(({id}) => {
                const currentMethod = flowMethods.find(({method}) => method.integration_id === id);
                const currentField = currentMethod.method.response.find(field => field.name === 'hidden_field_in_input_port');
                if (currentField) {
                    const { id: methodId } = currentMethod;
                    const { id: fieldId } = currentField;
                    setInnerSetParams({ flow_method_id: methodId, source_id: fieldId });
                    setHasHiddenField(true);
                }
            });
            setApiList(list);
        }
    }, [flowMethods, apiListRedux]);

    const onClose = () => {
        dispatch(setVisibleMatchModal(false));
        setVisibleFormTransformation(false);
        setResponseTemplate('');
    };

    const removeTransformation = () => {
        dispatch(setLoading(true));
        http.get(createUrl(`/user/flow/method/${contentId}/field/${field.id}`)).then((res) => {
            if (!res.data.field?.transformations) {
                enqueueSnackbar('This method does not have transformations', {
                    action: CustomSnackbar,
                    variant: 'warning'
                });
                return;
            }
            const transformationUrl = `/transformation/${res.data.field.transformations[0].id}/delete`;
            http.delete(createUrl(transformationUrl)).then(() => {
                dispatch(setVisibleMatchModal(false));
                setVisibleFormTransformation(false);
                setDefaultApiValue(null);
                setDefaultMethodValue(null);
                setDefaultFieldValue(null);
                setIsHiddenField(false);
                setHasHiddenField(false);
                setResponseTemplate('');
                dispatch(setContentMatchModal({}));
                updateFlowMethods();

                enqueueSnackbar('The Field has been unlinked', {
                    action: CustomSnackbar,
                    variant: 'success'
                });
            }).catch(() => {});
        }).finally(() => {
            dispatch(setLoading(false));
        });
    };

    const createTransformation = (params) => {
        dispatch(setLoading(true));

        http.post(createUrl('/transformation/add'), params).then((res) => {
            dispatch(setFlowMethodResponse(res.data.flowMethod));
            setVisibleFormTransformation(true);
            // restart default request
            getDefaultRequest();
            updateFlowMethods();
            enqueueSnackbar('Connection between fields has been added', {
                action: CustomSnackbar,
                variant: 'success'
            });
        }).catch((err) => {
            dispatch(setLoading(false));
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        });
    };

    // useEffect(() => {
    //     if (transformationList && presetOperation) {
    //         console.log(presetOperation, transformationList);
    //         http.post(createUrl(`/transformation/${transformationList[0].id}/update`), {
    //             operation_id: presetOperation,
    //             user_id: currentUserId,
    //             constant: '',
    //             source_flow_method_id: null,
    //             source_id: null
    //         }).then((res) => {
    //             getDefaultRequest();
    //             updateFlowMethods();
    //             setPresetOperation(0);
    //         });
    //     }
    // }, [transformationList, presetOperation]);

    const setTransformation = () => {
        dispatch(setLoading(true));

        http.post(createUrl('/transformation/add'), {
            target_flow_method_id: contentId,
            target_id: field.id,
            ...innerSetParams
        }).then((res) => {
            dispatch(setFlowMethodResponse(res.data.flowMethod));
            setVisibleFormTransformation(true);
            // restart default request
            getDefaultRequest().then(() => {
                updateFlowMethods().then(() => {
                    const operator = operatorsList.other.find(operation => operation.operation === 'set');
                    setPresetOperation(operator.id);
                });
            });

            enqueueSnackbar('Connection between fields has been added', {
                action: CustomSnackbar,
                variant: 'success'
            });
        }).catch((err) => {
            dispatch(setLoading(false));
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        });
    };

    const addTransformation = () => {
        const showMessageAndReturn = (message) => {
            enqueueSnackbar(message, {
                action: CustomSnackbar,
                variant: 'warning',
            });
        };

        const apiValue = getValues('api') || defaultApiValue?.value;
        const methodValue = getValues('method') || defaultMethodValue?.value;
        const fieldValue = getValues('field') || defaultFieldValue?.value;

        if (!apiValue) {
            showMessageAndReturn('Please select API');
            return;
        }

        if (!methodValue) {
            showMessageAndReturn('Please select method');
            return;
        }

        if (!fieldValue) {
            showMessageAndReturn('Please select field');
            return;
        }

        const paramsResponseFieldLink = {
            flow_id: id,
            method_id: parseInt(methodValue),
        };

        if (!paramsResponseFieldLink.method_id) {
            showMessageAndReturn('Please add new transformations to your chains');
            return;
        }

        dispatch(setLoading(true));

        const innerParams = {
            target_flow_method_id: contentId,
            flow_method_id: methodValue,
            source_id: fieldValue,
            target_id: field.id,
        };

        createTransformation(innerParams);
    };

    useEffect(() => {
        const subscription = watch((values, { name }) => {
            const { api, method } = values;
            switch (name) {
                case 'api':
                    if (api) {
                        const filteredMappedOutputs = flowMethods
                            .filter(({ method }) => method.integration_id === api)
                            .map(({id, method, name}) => ({
                                flow_method_id: id, ...method,
                                name: name ? name : method.name
                            }))
                            .filter(({ name }) => name !== 'Output Port')
                            .filter(({ flow_method_id }) => flow_method_id !== contentId);
                        setMethodsList(filteredMappedOutputs);
                        setFieldsList([]);
                        setValue('method', '');
                        setValue('field', '');
                    }
                    break;

                case 'method':
                    if (method) {
                        fetchFlowMethod(method);
                    }
                    break;

                default:
                    break;
            }
        });

        const fetchFlowMethod = (flowMethodId) => {
            if (flowMethodId) {
                http.get(createUrl(`/flow/method/${flowMethodId}/get`))
                    .then(({data}) => setFieldsList(data.flowMethod?.method?.response.filter(item => item.name !== 'hidden_field_in_input_port') || []))
                    .catch(() => setFieldsList([]));

                setValue('field', '');
            }
        };
        return () => subscription.unsubscribe();
    }, [watch, apiList, methodsList, flowMethods, createUrl, getValues, setValue, contentId]);

    // Set default field value with init component
    useEffect(() => {
        if (fieldsList.length > 0 && fieldDefault) {
            setValue('field', fieldDefault);
            setFieldDefault('')
        }
    }, [fieldsList, fieldDefault, setFieldDefault, setValue]);

    // Set default method value with init component
    useEffect(() => {
        if (methodsList.length > 0 && methodDefault) {
            setValue('method', methodDefault);
            setMethodDefault('')
        }
    }, [methodsList, methodDefault, setMethodDefault, setValue]);

    return (
        <Dialog
            sx={{
                '& .MuiDialog-paper': {
                    minWidth: '75vw',
                    minHeight: '70vh',
                    backgroundColor: '#FAFEFF',
                    border: '1px solid #FFFFFF',
                    borderRadius: '16px',
                    boxShadow: '1px 3px 15px rgba(0, 0, 0, 0.1)',
                },
            }}
            open={visible}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
        >
            <Box sx={{
                position: 'absolute',
                right: 0,
            }}>
                <IconButton size="large" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon fontSize="large"/>
                </IconButton>
            </Box>

            <DialogContent
                sx={{
                    display: 'flex',
                    padding: '35px 30px 20px 25px',
                    overflow: 'hidden',
                }}
            >
                <Grid container direction="row">
                    {/* left */}
                    <Grid item xs={4}
                          sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              overflowY: 'auto',
                              height: '100%'
                          }}>
                        <Box>
                            <Typography variant="h2" sx={{
                                marginBottom: '5px',
                                textAlign: 'center',
                                fontWeight: 500,
                                fontSize: '20px',
                                lineHeight: '24px',
                                color: '#3B465C',
                            }}>API Method to Match</Typography>

                            {isHiddenField ? (<Typography sx={{
                                textAlign: 'center',
                                color: '#949DB0',
                                wordBreak: 'break-word',
                                marginBottom: '35px'
                            }}>Data for the field {<Typography variant="span" sx={{color: '#1BBC6F'}}>
                                {name ? title.replace(method.name, name) : title}
                            </Typography>} is set manually.</Typography>) : defaultApiValue ? (
                                <Typography sx={{
                                    textAlign: 'center',
                                    color: '#949DB0',
                                    wordBreak: 'break-word',
                                    marginBottom: '35px'
                                }}>
                                    Data from the field {<Typography variant="span" sx={{color: '#1BBC6F'}}>
                                    {defaultApiValue?.label}.{defaultMethodValue?.label}.{defaultFieldValue?.label}
                                </Typography>} will be sent to {<Typography variant="span" sx={{color: '#1BBC6F'}}>
                                    {name ? title.replace(method.name, name) : title}
                                </Typography>} with the transformation configured to the right.
                                </Typography>) : (<>
                                    <Typography sx={{
                                        textAlign: 'center',
                                        color: '#949DB0',
                                        wordBreak: 'break-word',
                                        marginBottom: '0'
                                    }}>Destination field for this matching is {<Typography variant="span"
                                                                                           sx={{color: '#1BBC6F'}}>
                                        {name ? title.replace(method.name, name) : title}
                                    </Typography>}</Typography>
                                    <Typography sx={{textAlign: 'center', marginBottom: '35px', color: '#949DB0'}}>
                                        Please select source field below:
                                    </Typography>
                                </>)}

                            {/* form */}
                            {!isHiddenField ? (<>
                                {/* api */}
                                <FormControl sx={{width: '100%', marginBottom: '16px'}}>
                                    <InputLabel>API</InputLabel>
                                    <Controller
                                        name="api"
                                        control={control}
                                        rules={{required: true}}
                                        render={({field}) => (
                                            <Select {...field} label="API" disabled={!!defaultApiValue}>
                                                {apiList.map((item) => {
                                                    return (
                                                        <MenuItem
                                                            key={`${item.id}api`}
                                                            value={item.id}
                                                        >
                                                            {item.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        )}
                                    />
                                </FormControl>

                                {/* method */}
                                <FormControl sx={{width: '100%', marginBottom: '16px'}}>
                                    <InputLabel>Method</InputLabel>
                                    <Controller
                                        name="method"
                                        control={control}
                                        rules={{required: true}}
                                        render={({field}) => (
                                            <Select {...field} label="Method" disabled={!!defaultMethodValue}>
                                                {methodsList.map((item) => {
                                                    return (
                                                        <MenuItem key={`${item.flow_method_id}method`}
                                                                  value={item.flow_method_id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        )}
                                    />
                                </FormControl>

                                {/* field */}
                                <FormControl sx={{width: '100%', marginBottom: '16px'}}>
                                    <InputLabel>Field</InputLabel>
                                    <Controller
                                        name="field"
                                        control={control}
                                        rules={{required: true}}
                                        render={({field}) => (
                                            <Select {...field} label="Field" disabled={!!defaultFieldValue}>
                                                {fieldsList.map((item) => (
                                                    <MenuItem key={`${item.id}field`} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>

                            </>) : null}

                            {/* actions */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                    marginBottom: '24px',
                                    gap: '1rem'
                                }}
                            >
                                <Button
                                    variant="contained"
                                    startIcon={<TransformationButtonIcon/>}
                                    disabled={disableAddTransformation}
                                    onClick={() => {
                                        addTransformation();
                                    }}
                                >
                                    Add transformation
                                </Button>

                                {hasHiddenField ? (<Button
                                    variant="contained"
                                    startIcon={<TransformationButtonIcon/>}
                                    disabled={disableAddTransformation}
                                    onClick={() => {
                                        setTransformation();
                                    }}>
                                    Set value
                                </Button>) : null}

                                <Button
                                    variant="outlined"
                                    disabled={!disableAddTransformation}
                                    onClick={() => {
                                        removeTransformation();
                                    }}
                                >
                                    Remove connection
                                </Button>
                            </Box>
                        </Box>
                        { !isHiddenField && responseTemplate && (<Box sx={{
                            border: '1px solid rgba(148, 157, 176, 0.25)',
                            borderRadius: '4px',
                            padding: '10px',
                            backgroundColor: '#e8ecf0',
                            marginBottom: '10px'
                        }}>
                            <Typography
                                sx={{
                                    marginRight: '16px',
                                    marginBottom: '16px',
                                    fontSize: '13px',
                                    fontWeight: '500',
                                    letterSpacing: '0.1em',
                                    color: 'rgba(65, 77, 101, 0.85)',
                                }}
                            >
                                Response Structure from
                                <Typography
                                    variant="span"
                                    sx={{
                                        color: '#1BBC6F',
                                    }}
                                > {defaultApiValue?.label}.{defaultMethodValue?.label}
                                </Typography>:
                            </Typography>
                            <BeautifyJson jsonStr={responseTemplate}/>
                        </Box>)}
                    </Grid>
                    {/* right */}
                    <Grid
                        item
                        xs={8}
                        sx={{
                            height: '100%',
                            paddingLeft: '25px',
                            borderRadius: '10px',
                            overflow: 'hidden',
                        }}
                    >
                        {/* first layout before click */}
                        {!visibleFormTransformation && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                    padding: '25px',
                                    background: '#F2F7F9',
                                    border: '1px solid rgba(148, 157, 176, 0.25)',
                                    borderRadius: '10px',
                                }}
                            >
                                <TransformationIcon/>
                                <Typography
                                    sx={{
                                        marginTop: '20px',
                                        marginBottom: '5px',
                                        fontWeight: '700',
                                        fontSize: '15px',
                                        lineHeight: '18px',
                                        letterSpacing: '0.15em',
                                        color: '#283348',
                                    }}
                                >
                                    Transformation
                                </Typography>
                                <Typography
                                    sx={{
                                        color: '#949DB0',
                                    }}
                                >
                                    At first, select API, Method and Input/Output
                                </Typography>
                            </Box>
                        )}

                        {/* second layout with forms */}
                        {visibleFormTransformation && (
                            <>
                                <Box
                                    sx={{
                                        padding: '15px 15px 25px 25px',
                                        background: '#F2F7F9',
                                        border: '1px solid rgba(148, 157, 176, 0.25)',
                                        borderRadius: '10px',
                                        height: 'inherit',
                                    }}
                                >
                                    <Box
                                        className="scroll-container"
                                        sx={{
                                            paddingTop: '10px',
                                            paddingRight: '15px',
                                            overflowY: 'auto',
                                            height: 'inherit',
                                        }}
                                    >
                                        {memoizedTransformations && (<FlowTransformationList
                                            presetOperation={presetOperation}
                                            isHiddenField={isHiddenField}
                                            transformations={memoizedTransformations}
                                            addNewChain={createTransformation}
                                            defaultTitle={defaultTitle}
                                            setVisibleFormTransformation={setVisibleFormTransformation}
                                        />)}
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default FlowMatchModal;
