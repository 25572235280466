import React, { useCallback, useEffect, useState } from 'react';
import http from 'Utils/http';
import { Input, Box, Button, IconButton } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";
import { setFlow } from 'Actions/flow';
import { setLoading } from 'Actions/loading';
import { useCreateUrl } from 'Hooks/useCreateUrl';
import { InputIcon, OutputIcon, EditIcon } from 'Shared/Icons';
import SaveIcon from '@mui/icons-material/Save';
import FlowRunFlow from 'Shared/flows/flows-interface/FlowRunFlow';
import FlowImportFlow from 'Shared/flows/flows-interface/FlowImportFlow';

const FlowTools = ({flowId, name, setInitiateVisible, setFinalizationVisible}) => {
    const dispatch = useDispatch();
    const { createUrl } = useCreateUrl();

    const [isEdit, setIsEdit] = useState(false);
    const [isRunModal, setIsRunModal] = useState(false);
    const [isExportFlow, setIsExportFlow] = useState(false);
    const [response, setResponse] = useState('');

    const {
        register,
        setValue,
        getValues
    } = useForm({
        defaultValues: {
            name: name
        }
    });

    useEffect(() => {
        setValue('name', name);
    }, [name, setValue]);

    const updateFlow = useCallback(async () => {
        dispatch(setLoading(true));
        const name = getValues('name');
        await http.post(createUrl(`/flow/${flowId}/update`), {
            name
        }).then((res) => {
            dispatch(setFlow(res.data.flow));
        }).finally(() => {
            dispatch(setLoading(false));
            setIsEdit(false);
        });
    }, [createUrl, flowId, dispatch, getValues]);

    useEffect(() => {
        if(isExportFlow) {
            http.get(`/flow/${flowId}/export`).then(res => {
                setResponse(JSON.stringify(res.data));
            }).catch(err => {
            }).finally(() => {
                dispatch(setLoading(false));
            });
        }
    }, [isExportFlow, setResponse, flowId, dispatch]);

    return (
        <>
            <FlowImportFlow id={flowId} visible={!!response} setVisible={setIsExportFlow} response={response} setResponse={setResponse} name={name} />
            <FlowRunFlow id={flowId} visible={isRunModal} setVisible={setIsRunModal} />
            <Box
                sx={{
                    display: 'flex',
                    position: 'relative',
                    marginTop: '-10px',
                    marginBottom: '30px',
                    marginLeft: 'auto',
                    paddingBottom: '20px',
                    backgroundColor: 'transparent',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        left: '-35px',
                        right: '-35px',
                        bottom: 0,
                        height: '1px',
                        backgroundColor: '#252D3D',
                    }}
                ></Box>

                <Box>
                    <Button
                        variant="contained"
                        startIcon={<InputIcon/>}
                        onClick={() => {
                            setInitiateVisible(true);
                        }}
                        sx={{
                            marginRight: '20px',
                            color: '#3B465C',
                            backgroundColor: '#F2F7F9',
                            borderRadius: '4px',
                            '&:hover': {
                                backgroundColor: '#DADEE0',
                                boxShadow: 'none',
                            },
                            '&:active': {
                                backgroundColor: '#DADEE0',
                                boxShadow: 'inset 0px 4px 10px rgba(2, 10, 38, 0.45)',
                            },
                        }}
                    >
                        Define Inputs
                    </Button>
                    <Button
                        variant="contained"
                        endIcon={<OutputIcon/>}
                        onClick={() => { setFinalizationVisible(true); }}
                        sx={{
                            color: '#3B465C',
                            backgroundColor: '#F2F7F9',
                            borderRadius: '4px',
                            '&:hover': {
                                backgroundColor: '#DADEE0',
                                boxShadow: 'none',
                            },
                            '&:active': {
                                backgroundColor: '#DADEE0',
                                boxShadow: 'inset 0px 4px 10px rgba(2, 10, 38, 0.45)',
                            },
                        }}
                    >
                        Define Outputs
                    </Button>
                </Box>

                <Box
                    sx={{
                        marginLeft: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Button
                        sx={{
                            color: '#3B465C',
                            backgroundColor: '#2FDE8A',
                            fontWeight: 500,
                            borderRadius: '4px',
                            textTransform: 'uppercase',
                            marginRight: '15px',
                            '&:hover': {
                                backgroundColor: '#2AC87C',
                                boxShadow: 'none',
                            },
                            '&:active': {
                                backgroundColor: '#2AC87C',
                                boxShadow: 'inset 0px 4px 10px rgba(2, 10, 38, 0.45)',
                            },
                        }}
                        onClick={() => setIsExportFlow(true)}
                    >
                        Export Flow
                    </Button>
                    <Button
                        sx={{
                            color: '#3B465C',
                            backgroundColor: '#2FDE8A',
                            fontWeight: 500,
                            borderRadius: '4px',
                            textTransform: 'uppercase',
                            '&:hover': {
                                backgroundColor: '#2AC87C',
                                boxShadow: 'none',
                            },
                            '&:active': {
                                backgroundColor: '#2AC87C',
                                boxShadow: 'inset 0px 4px 10px rgba(2, 10, 38, 0.45)',
                            },
                        }}
                        onClick={() => setIsRunModal(true)}
                        variant="contained"
                    >
                        Run Flow
                    </Button>
                </Box>
            </Box>
            <Box component="form"
                 sx={{
                     position: 'relative',
                     display: 'flex',
                     width: '100%'
                 }}>
                <Box
                    style={{
                        position: 'relative',
                        marginRight: '25px'
                    }}
                >
                    {!isEdit && (
                        <IconButton
                            style={{
                                width: '50px',
                                height: '50px',
                                backgroundColor: '#414D65'
                            }}
                            onClick={() => {
                                setIsEdit(true);
                            }}
                        >
                            <EditIcon style={{ color: '#F2F7F9' }}  />
                        </IconButton>
                    )}
                    {isEdit && (
                        <IconButton
                            style={{
                                width: '50px',
                                height: '50px',
                                backgroundColor: '#414D65'
                            }}
                            onClick={updateFlow}
                        >
                            <SaveIcon style={{ color: '#F2F7F9' }}  />
                        </IconButton>
                    )}
                </Box>
                <Box
                    sx={{
                    position: 'relative',
                    width: '95%',
                    '@media (min-width: 600px)': {
                        width: '80%',
                    },
                    '@media (min-width: 960px)': {
                        width: '60%',
                    },
                    '@media (min-width: 1280px)': {
                        width: '40%',
                    },
                }}>
                    <Input
                        sx={{
                            width: '100%',
                            fontWeight: 500,
                            fontSize: '28px',
                            lineHeight: '34px',
                            marginBottom: '20px',
                            color: '#F2F7F9',
                            '&:after': {
                                display: !isEdit ? 'none' : 'block',
                                borderColor: '#F2F7F9'
                            },
                            '&:before': {
                                display: !isEdit ? 'none' : 'block',
                                borderColor: '#F2F7F9'
                            },
                            ' input:disabled': {
                                color: '#F2F7F9',
                                WebkitTextFillColor: '#F2F7F9'
                            }
                        }}
                        disabled={!isEdit}
                        {...register('name', {
                            required: true,
                        })} />
                </Box>
            </Box>
        </>
    );
};

export default FlowTools;
