import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Transition } from 'Shared/Transition';
import { CustomSnackbar } from 'Shared/CustomSnackbar';

function BeautifyJson({ jsonStr }) {
    const beautifiedJson = JSON.stringify(JSON.parse(jsonStr), null, 4);

    return (
        <pre style={{ overflow: 'auto', maxHeight: '300px' }}>{beautifiedJson}</pre>
    );
}
function convertFileName(input) {
    let result = input.toLowerCase();
    result = result.replace(/\s+/g, '_');
    result = result + '.json';
    return result;
}

const FlowImportFlow = ({name, visible, setVisible, response, setResponse}) => {
    const { enqueueSnackbar } = useSnackbar();

    const onClose = () => {
        setVisible(false);
        setResponse('');
    };

    const copyToClipboard = () => {
        if (response) {
            navigator.clipboard.writeText(JSON.stringify(JSON.parse(response), null, 4))
                .then(() => {
                    enqueueSnackbar(`The response data has been copied to the clipboard.`, {
                        action: CustomSnackbar,
                        variant: 'success'
                    });
                    onClose();
                }).catch(() => {
                enqueueSnackbar('Unable to copy response to clipboard', {
                    action: CustomSnackbar,
                    variant: 'error'
                });
            });
        }
    };

    const downloadFile = () => {
        if (response) {
            const fileData = JSON.stringify(JSON.parse(response), null, 4);
            const blob = new Blob([fileData], { type: 'application/json' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = convertFileName(name);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
    };

    return (
        <Dialog
            open={visible}
            onClose={onClose}
            TransitionComponent={Transition}
            sx={{
                '& .MuiDialog-container': {
                    alignItems: 'flex-start',
                    paddingTop: '150px',
                },
                '& .MuiDialog-paper': {
                    minWidth: '450px',
                    maxWidth: '900px'
                }
            }}
        >
            <Box>
                <DialogTitle
                    className="h1"
                    variant="h1"
                    sx={{
                        paddingBottom: '6px',
                        textAlign: 'center',
                        color: '#3B465C',
                    }}
                >
                    Export Flow
                </DialogTitle>
                <DialogContent sx={{ maxHeight: '560px' }}>
                    {response && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <BeautifyJson jsonStr={response} />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions
                    sx={{
                        padding: '16px 24px',
                    }}
                >
                    <Button onClick={downloadFile} variant="outlined">
                        Download
                    </Button>
                    <Button onClick={copyToClipboard} variant="outlined">
                        Copy
                    </Button>
                    <Button onClick={onClose} variant="outlined">
                        Close
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default FlowImportFlow;
