import axios from 'axios';
import { ROUTES } from '../constants/routes';

// Instance without 401 error handling
export const httpWithout401 = axios.create({
    baseURL: process.env.REACT_APP_API_URL || '/api',
});

// Instance with 401 error handling
const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL || '/api',
});

// Add a request interceptor to check token before each request
http.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('token');
        if (token) {
            // Encode token to ensure it uses valid characters
            token = encodeURIComponent(token);
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Add a response interceptor to handle 401 errors
http.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            window.location.href = `${window.location.origin}${ROUTES.LOGIN}?message_error=Session has expired. Please re-authorize.`;
        }
        return Promise.reject(error);
    }
);

export default http;
