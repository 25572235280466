import React, { Suspense, useCallback, useEffect, useState } from 'react';
import http from 'Utils/http';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { ROUTES } from 'Constants/routes';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch} from 'react-redux';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import { setAPIs } from 'Actions/APIs';
import { setCurrentUser, login, logout } from 'Actions/auth';
import { setCompanies } from 'Actions/companies';
import MyFlowsPage from 'Pages/users/MyFlowsPage';
import IntegrationsListPage from 'Pages/IntegrationsListPage';
import AdminFlowsPage from 'Pages/AdminFlowsPage';
import EditFlowPage from 'Pages/EditFlowPage';
import LoggingPage from 'Pages/LoggingPage';
import MethodsPage from 'Pages/MethodsPage';
import MethodsParametersPage from 'Pages/MethodsParametersPage';
import Loader from 'Shared/Loader';

//common
const LoginPage = React.lazy(() => import('Pages/auth/LoginPage'));
const ForgotPasswordPage = React.lazy(() => import('Pages/auth/ForgotPasswordPage'));
const PasswordResetPage = React.lazy(() => import('Pages/auth/PasswordResetPage'));
const RegistrationPage = React.lazy(() => import('Pages/auth/RegistrationPage'));
const NotFoundPage = React.lazy(() => import('Pages/NotFoundPage'));

//admin
const UsersListPage = React.lazy(() => import('Pages/users/UsersListPage'));
const UserDetailsPage = React.lazy(() => import('Pages/users/UserDetailsPage'));
const AdminIntegrationsListPage = React.lazy(() => import('Pages/AdminIntegrationsListPage'));
const AddUserPage = React.lazy(() => import('Pages/auth/AddUserPage'));
const AddIntegrationPage = React.lazy(() => import('Pages/AddIntegrationPage'));

//user
const MyAccountPage = React.lazy(() => import('Pages/users/MyAccountPage'));

const AppRouter = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const access = useSelector((state) => state.auth.user.access);
    const token = useSelector((state) => state.auth.user.token);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const [update, setUpdate] = useState(false);
    const [hasError, setHasError] = useState(false);

    const updateToken = useCallback(async () => {
        if (!update) return false;

        try {
            const { data: { user, authorisation } } = await http.post('/refresh');

            const updatedUser = {
                token: authorisation.token,
                access: user.roles[0]?.name || '',
                ...user
            };

            setUpdate(false);
            dispatch(login(updatedUser));
        } catch (error) {
            if (hasError) return;
            if (error.response?.status === 401) {
                enqueueSnackbar('Session has expired. Please re-authorize.', {
                    action: CustomSnackbar,
                    variant: 'warning'
                });

                dispatch(logout(navigate));
            } else {
                enqueueSnackbar('An error occurred while refreshing the token.', {
                    action: CustomSnackbar,
                    variant: 'error'
                });
            }
        }
    }, [update, dispatch, enqueueSnackbar, navigate, hasError]);

    useEffect(() => {
        if (sessionStorage.getItem('companies')) {
            const companies = JSON.parse(sessionStorage.getItem('companies'));
            dispatch(setCompanies([...companies]));
            return;
        }
        http.get('/companies').then((res) => {
            dispatch(setCompanies([...res.data.companies]));
            sessionStorage.setItem('companies', JSON.stringify(res.data.companies));
        }).catch(() => {});
    }, [isAuthenticated, dispatch]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const currentUser = localStorage.getItem('currentUser');
            const currentUserObj = currentUser ? { ...JSON.parse(currentUser) } : {};
            const user = {
                token,
                ...currentUserObj
            };

            dispatch(setCurrentUser(user)); // Assuming you have a Redux action to set the current user
            http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
    }, [dispatch]);

    useEffect(() => {
        if (update) {
            updateToken().then(r => {
                setHasError(false);
            });
        }
    }, [update, updateToken, setHasError]);

    useEffect(() => {
        const checkTokenExpiration = () => {
            if (!token) {
                return false;
            }
            const jwt = JSON.parse(atob(token.split('.')[1]));
            const expirationTime = (jwt && jwt.exp && jwt.exp * 1000) || null;
            if (!expirationTime) {
                return false;
            }

            const intervalId = setInterval(() => {
                if ((expirationTime - 300000) < Date.now() && !update) {
                    clearInterval(intervalId);
                    setUpdate(true);
                }
            }, 30000);
        };

        checkTokenExpiration();
    }, [update, token]);

    useEffect(() => {
        // Check if http.interceptors.response exists and has the eject method
        if (http && http.interceptors && http.interceptors.response && typeof http.interceptors.response.eject === 'function') {
            const interceptor = http.interceptors.response.use(
                (resp) => resp,
                async (error) => {
                    if (error.response?.status === 401 && !hasError) {
                        setHasError(true);
                        enqueueSnackbar(`Session has expired. Please re-authorize`, {
                            action: CustomSnackbar,
                            variant: 'warning'
                        });
                        dispatch(logout(navigate));
                    }
                    return Promise.reject(error);
                }
            );

            // Cleanup function to remove the interceptor
            return () => {
                http.interceptors.response.eject(interceptor);
            };
        } else {
            console.error("http.interceptors.response.eject is not available. Make sure http and its interceptors are properly set up.");
        }
    }, [dispatch, enqueueSnackbar, navigate, setHasError, hasError]);

    return (
        <>
            <Suspense fallback={<Loader/>}>
                {isAuthenticated && (
                    <Routes>
                        {access === 'User' && (
                            <>
                                <Route path={ROUTES.HOME} exact element={<Navigate to={ROUTES.MY_ACCOUNT}/>}/>
                                <Route path={ROUTES.LOGIN} exact element={<Navigate to={ROUTES.MY_ACCOUNT}/>}/>
                                <Route path={ROUTES.MY_ACCOUNT} element={<MyAccountPage/>}/>
                                <Route path={ROUTES.INTEGRATIONS} element={<IntegrationsListPage/>}/>
                                {/* need refactor this */}
                                <Route path={ROUTES.FLOWS} element={<MyFlowsPage/>}/>
                                <Route path={ROUTES.FLOW_BY_ID} element={<EditFlowPage/>}/>
                                <Route path={ROUTES.LOGGING} element={<LoggingPage/>}/>
                            </>
                        )}

                        {access === 'Admin' && (
                            <>
                                <Route path={ROUTES.HOME} exact element={<Navigate to={ROUTES.INTEGRATIONS}/>}/>
                                <Route path={ROUTES.LOGIN} exact element={<Navigate to={ROUTES.INTEGRATIONS}/>}/>
                                <Route path={ROUTES.USERS} element={<UsersListPage/>}/>
                                <Route path={ROUTES.USERS_BY_ID} element={<UserDetailsPage/>}/>
                                <Route path={ROUTES.INTEGRATIONS} element={<AdminIntegrationsListPage/>}/>
                                <Route path={ROUTES.FLOWS} element={<AdminFlowsPage/>}/>
                                <Route path={ROUTES.FLOW_BY_ID} element={<EditFlowPage/>}/>
                                <Route path={ROUTES.FLOW_BY_ID_WITH_CURRENT_USER} element={<EditFlowPage/>}/>
                                <Route path={ROUTES.LOGGING} element={<LoggingPage/>}/>
                                <Route path={ROUTES.METHODS_PARAMS} element={<MethodsParametersPage/>}/>
                                <Route path={ROUTES.METHODS} element={<MethodsPage/>}/>
                                <Route path={ROUTES.ADD_USER} element={<AddUserPage/>}/>
                                <Route path={ROUTES.ADD_INTEGRATION} element={<AddIntegrationPage/>}/>
                            </>
                        )}

                        {/* all authenticated */}
                        <Route path={ROUTES.PASSWORD_RESET} element={<PasswordResetPage/>}/>
                        <Route path={ROUTES.NOT_FOUND} element={<NotFoundPage/>}/>
                        <Route path={ROUTES.ALL} element={<NotFoundPage/>}/>
                    </Routes>
                )}

                {/* all access */}
                {!isAuthenticated && (
                    <Routes>
                        <Route path={ROUTES.HOME} exact element={<Navigate to={ROUTES.LOGIN}/>}/>
                        <Route path={ROUTES.LOGIN} exact element={<LoginPage/>}/>
                        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordPage/>}/>
                        <Route path={ROUTES.REGISTRATION} element={<RegistrationPage/>}/>
                        <Route path={ROUTES.PASSWORD_RESET} element={<PasswordResetPage/>}/>
                        <Route path={ROUTES.NOT_FOUND} element={<NotFoundPage/>}/>
                        <Route path={ROUTES.ALL} element={<NotFoundPage/>}/>
                    </Routes>
                )}
            </Suspense>
        </>
    );
};

export default AppRouter;
